export default function() {
 const bodyScrollLock = require('body-scroll-lock');
 const disableBodyScroll = bodyScrollLock.disableBodyScroll;
 const enableBodyScroll = bodyScrollLock.enableBodyScroll;
    
  // 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav). 
  const targetElement = document.querySelector(".ht-mobile-nav");
  
  if (document.querySelector('.ht-mobile-nav-toggle')) {
    document.querySelector('.ht-mobile-nav-toggle').addEventListener('click', function (e) {
      if (document.querySelector('.ht-mobile-nav').classList.contains('closed')) {
        document.querySelector('.ht-mobile-nav').classList.remove('closed');
        document.querySelector('.ht-mobile-nav').classList.add('open');
        document.querySelector('.ht-mobile-nav-toggle').classList.remove('closed');
        document.querySelector('.ht-mobile-nav-toggle').classList.add('open');
        document.querySelector('html, body').classList.add('locked');
        document.querySelector('header').classList.add('scrolled');
        disableBodyScroll(targetElement);
      } else {
        document.querySelector('.ht-mobile-nav').classList.remove('open');
        document.querySelector('.ht-mobile-nav').classList.add('closed');
        document.querySelector('.ht-mobile-nav-toggle').classList.remove('open');
        document.querySelector('.ht-mobile-nav-toggle').classList.add('closed');
        document.querySelector('html, body').classList.remove('locked');
        if(window.pageYOffset <= 0) {
          document.querySelector('header').classList.remove('scrolled');
        }
        enableBodyScroll(targetElement);
      }
    });
  }
}
