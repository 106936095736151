window.$ = $;
export default function() {
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    var target = $.attr(this, 'href').replace('#', '');
    $('html, body').animate({
      scrollTop: $('a[name="'+ target + '"]').offset().top
    }, 1000, 'swing');
  });
}
