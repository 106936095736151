// JS v1.07
// js comment
if (!NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
import "./vendor/modernizr";
import validation from "./vendor/validation";
import mobileNavToggle from "./parts/_mobile-nav-toggle";
import SimpleLightbox from "simple-lightbox";
import scrollAnchor from "./parts/_scroll-to-anchor";
import Masonry from "masonry-layout";
window.clickMob = "click"; // 'ontouchstart' in window || navigator.maxTouchPoints ? 'touchstart' : 'click'
window.$ = $;
window.mobileAndTabletcheck = function () {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

mobileNavToggle();
scrollAnchor();

function GetIEVersion() {
  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf("MSIE");
  // If IE, return version number.
  if (Idx > 0) {
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
  } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
    return 11;
  } else {
    return 0;
  }
}
$(function () {
  validation();
  init3DGallery();
  openModal();

  if (window.mobileAndTabletcheck()) {
    $("body").addClass("mobile");
  }
  if (!$("body").hasClass("home") && $("#js-map").length > 0) {
    $("#js-map").addClass("ie-margin");
    initMap();
    $(
      ".page-map .c-icon-menu-item:first-child, .location-and-route .c-icon-menu-item:first-child"
    )
      .trigger("click")
      .addClass("active");
    var image = $(
      ".page-map .c-icon-menu-item.active, .location-and-route .c-icon-menu-item.active"
    ).data("class");
    var source;
    var newSource;
    if (
      $(
        ".page-map .c-icon-menu-item:first-child, .location-and-route .c-icon-menu-item:first-child"
      ).hasClass("active")
    ) {
      source = $(
        ".page-map .c-icon-menu-item:first-child, .location-and-route .c-icon-menu-item:first-child"
      )
        .children()
        .first()
        .attr("src");
      newSource = source.replace(image + ".svg", image + "_hover.svg");
      $(
        ".page-map .c-icon-menu-item:first-child, .location-and-route .c-icon-menu-item:first-child"
      )
        .children()
        .first()
        .attr("src", newSource);
    }
  }

  // Get IE or Edge browser version
  var version = detectIE();
  var IE11 = false;
  if (version) {
    IE11 = true;
  }

  function detectIE() {
    var ua = window.navigator.userAgent;

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
    // other browser
    return false;
  }
  if (Modernizr.webgl && !IE11) {
    $(".c-building-map").removeClass("hide");
    $(".c-building-map").addClass("show");
    $(".c-building-img").removeClass("show");
    $(".c-building-img").addClass("hide");
    $(".h3-large").removeClass("z-index-high");
  } else if (!Modernizr.webgl || IE11) {
    $(".c-building-map").removeClass("show");
    $(".c-building-map").addClass("hide");
    $(".c-building-img").removeClass("hide");
    $(".c-building-img").addClass("show");
    $(".h3-large").addClass("z-index-high");
  }
  if ($("body").hasClass("diensten") || $("body").hasClass("services")) {
    if (Modernizr.webgl && !IE11) {
      delayed();
    }
    // icon active
    $(".c-icon-menu-item").each(function () {
      var image = $(this).data("class");
      var source;
      var newSource;
      if ($(this).hasClass("active")) {
        source = $(this).children().first().attr("src");
        newSource = source.replace(image + ".svg", image + "_hover.svg");
        $(this).children().first().attr("src", newSource);
      }
    });
    setTimeout(function () {
      // Get IE or Edge browser version
      var version = detectIE();
      var IE11 = false;
      if (version) {
        IE11 = true;
      }

      function detectIE() {
        var ua = window.navigator.userAgent;

        var trident = ua.indexOf("Trident/");
        if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf("rv:");
          return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
        }
        // other browser
        return false;
      }
      $(
        ".diensten .c-icon-menu-item:first-child, .c-content-stream-5 .c-icon-menu-item:first-child, .services .c-icon-menu-item:first-child"
      ).trigger("click");
      if (!Modernizr.webgl && IE11) {
        var cat = $(
          ".diensten .c-icon-menu-item:first-child, .c-content-stream-5 .c-icon-menu-item:first-child, .services .c-icon-menu-item:first-child"
        ).data("class");
        $(".flag").data("category", cat).trigger("click");
      }
    }, 3000);
  }
  // initialize header gallery
  $("body .c-header-visual__wrapper")
    .slick({
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
    })
    .resize();
  // initialize footer gallery
  $("body:not(.mobile) .c-footer-gallery")
    .slick({
      dots: true,
      dotsClass: "c-gallery-nav",
      prevArrow: '<div class="c-gallery-nav__previous"></div>',
      nextArrow: '<div class="c-gallery-nav__next"></div>',
      autoplay: true,
    })
    .resize();
  $("body.mobile .c-footer-gallery")
    .slick({
      dots: true,
      dotsClass: "c-gallery-nav",
      arrows: false,
      autoplay: true,
    })
    .resize();
  // initialize wiredscore gallery
  $("body:not(.mobile) .wiredscore-gallery")
    .slick({
      dots: true,
      dotsClass: "c-gallery-nav",
      prevArrow: '<div class="c-gallery-nav__previous"></div>',
      nextArrow: '<div class="c-gallery-nav__next"></div>',
      autoplay: true,
    })
    .resize();
  $("body.mobile .wiredscore-gallery")
    .slick({
      dots: true,
      dotsClass: "c-gallery-nav",
      arrows: false,
      autoplay: true,
    })
    .resize();
  // new style gallery
  $("body.het-vernieuwde-delftse-poort .c-gallery")
    .slick({
      dots: true,
      dotsClass: "c-gallery-nav",
      prevArrow: '<div class="c-gallery-nav__previous"></div>',
      nextArrow: '<div class="c-gallery-nav__next"></div>',
      autoplay: true,
    })
    .resize();
  // toggle the panels
  // if ($('body').hasClass('mobile')) {
  //   $('.toggle-panel').on(clickMob, function () {
  //     var num = $(this).data('id') + 1;
  //     var nextClass = '.c-content-stream-' + num;
  //     var nextId = '#o-layout__content-' + num;
  //     if ($(nextClass).children(':not(.c-panel-close-button)').length > 0) {
  //       if ($(this).hasClass('closed')) {
  //         $(nextId).toggleClass('closed open')
  //         $(this).toggleClass('closed open')
  //         $('html, body').addClass('noscroll')
  //         $('html, body').animate({
  //           scrollTop: ($(nextId).offset().top - 88)
  //         }, 400)
  //       } else {
  //         $(nextId).toggleClass('closed open')
  //         $(this).toggleClass('closed open')
  //         $('html, body').removeClass('noscroll')
  //       }
  //     }
  //   })
  // } else {
  //   $('.toggle-panel').on(clickMob, function () {
  //     var togglePanel = new Event('togglePanel');
  //     var num = $(this).data('id') + 1;
  //     var nextClass = '.c-content-stream-' + num;
  //     var nextId = '#o-layout__content-' + num;
  //     if ($(nextClass).children(':not(.c-panel-close-button)').length > 0) {
  //       if ($(this).hasClass('closed')) {
  //         $(nextId).slideDown().css('display', 'grid')
  //         $(nextId).toggleClass('closed open')
  //         $(this).toggleClass('closed open')
  //         $('html, body').animate({
  //           scrollTop: ($(nextClass).offset().top - 88)
  //         }, 400, function () {
  //           if (num = 6) {
  //             window.dispatchEvent(togglePanel)
  //             $('.features').addClass('show')
  //           }
  //         })
  //       } else {
  //         $(nextId).slideUp()
  //         $(nextId).toggleClass('closed open')
  //         $(this).toggleClass('closed open')
  //         if (num = 6) {
  //           $('.features').removeClass('show')
  //         }
  //       }
  //     }
  //   })
  // }
  // panel close buttons
  // $('.c-panel-close-button').on(clickMob, function () {
  //   var closeId = $(this).data('close')
  //   if ($('body').hasClass('mobile')) {
  //     $(this).parent().parent().toggleClass('closed open')
  //     $('.c-button[data-id="' + closeId + '"]').toggleClass('closed open')
  //     $('html, body').removeClass('noscroll')
  //   } else {
  //     $(this).parent().parent().toggleClass('closed open')
  //     $(this).parent().parent().slideUp()
  //     $('.c-button[data-id="' + closeId + '"]').toggleClass('closed open')
  //     $('html, body').removeClass('noscroll')
  //     $('.features').removeClass('show')
  //   }
  // })
  // initialize 3d model
  // $('a.toggle-panel[data-id="5"]').one(clickMob, function () {
  //   if (Modernizr.webgl && !IE11) {
  //     delayed()
  //   }
  //   // icon active
  //   $('.c-icon-menu-item').each(function () {
  //     var image = $(this).data('class')
  //     var source
  //     var newSource
  //     if ($(this).hasClass('active')) {
  //       source = $(this).children().first().attr('src')
  //       newSource = source.replace(image + '.svg', image + '_hover.svg')
  //       $(this).children().first().attr('src', newSource)
  //     }
  //   })
  //   $('.diensten .c-icon-menu-item:first-child, .c-content-stream-5 .c-icon-menu-item:first-child, .services .c-icon-menu-item:first-child').trigger('click')
  // })
  // initialize google maps
  $('a.toggle-panel[data-id="9"]').one(clickMob, function () {
    initMap();
    $(".c-content-stream-9 .c-icon-menu-item:first-child").trigger("click");
  });
  // Context menu building
  $(".c-context-menu.service .c-button--block-left").on(clickMob, function () {
    var category = $(".openItem").data("category");
    var array = $('.c-context-item[data-category="' + category + '"]');
    var current = array.filter(".openItem");
    array.each(function (index) {
      if (this === current.get(0)) {
        var previous = index - 1;
        if (previous === -1) {
          previous = array.length - 1;
        }
        $(array[index]).removeClass("openItem");
        $(array[previous]).addClass("openItem");
      }
    });
  });
  $(".c-context-menu.service .c-button--block-right").on(clickMob, function () {
    var category = $(".openItem").data("category");
    var array = $('.c-context-item[data-category="' + category + '"]');
    var current = array.filter(".openItem");
    array.each(function (index) {
      if (this === current.get(0)) {
        var next = index + 1;
        if (next === array.length) {
          next = 0;
        }
        $(array[index]).removeClass("openItem");
        $(array[next]).addClass("openItem");
      }
    });
  });
  // toggle menu icons on click
  $(".c-icon-menu-item").on(clickMob, function () {
    $(".c-icon-menu-item").not(this).removeClass("active");
    $(this).addClass("active");
    var frameModelNumber = $(this).data("id");
    window.frameModel = frameModelNumber;
    $(".c-icon-menu-item").each(function () {
      var image = $(this).data("class");
      var source;
      var newSource;
      if ($(this).hasClass("active")) {
        source = $(this).children().first().attr("src");
        newSource = source.replace(image + ".svg", image + "_hover.svg");
        $(this).children().first().attr("src", newSource);
      } else {
        source = $(this).children().first().attr("src");
        newSource = source.replace(image + "_hover.svg", image + ".svg");
        $(this).children().first().attr("src", newSource);
      }
    });
    if (
      $(this).parents(".diensten").length > 0 ||
      $(this).parents(".services").length > 0 ||
      $(this).parents(".c-content-stream-5").length > 0
    ) {
      if (!Modernizr.webgl || IE11) {
        var ieImage = $(this).data("class");
        $(".fallback-img:not(" + ieImage + ")")
          .removeClass("show")
          .addClass("hide");
        $(".fallback-img." + ieImage)
          .removeClass("hide")
          .addClass("show");
      }
    }
  });
  // icon hover
  $(".c-icon-menu-item").hover(
    function () {
      var image = $(this).data("class");
      var source = $(this).children().first().attr("src");
      var newSource = source.replace(image + ".svg", image + "_hover.svg");
      $(this).children().first().attr("src", newSource);
    },
    function () {
      if (!$(this).hasClass("active")) {
        var image = $(this).data("class");
        var source = $(this).children().first().attr("src");
        var newSource = source.replace(image + "_hover.svg", image + ".svg");
        $(this).children().first().attr("src", newSource);
      }
    }
  );
  // toggle Menu
  $(".c-menu-open").on(clickMob, function () {
    toggleMenu();
    $("html, body").addClass("noscroll");
    $(".c-menu-open").addClass("hidden");
  });
  $(".c-menu-close").on(clickMob, function () {
    toggleMenu();
    $("html, body").removeClass("noscroll");
    $(".c-menu-open").removeClass("hidden");
  });
  // open overlay
  $(".button-overlay").on(clickMob, function () {
    if ($("body").hasClass("mobile")) {
      $("#c-overlay").addClass("show-overlay-mobile");
      $("#c-overlay-container").addClass("open");
    } else {
      $("#c-overlay").addClass("show-overlay");
      $("#c-overlay-container").addClass("open");
    }
    $("html, body").addClass("noscroll");
    $("#c-overlay-container").load(
      $(this).data("link") + " #o-layout__content",
      function () {
        $("#c-overlay-container .c-content-stream").unwrap();
        // initialize overlay gallery
        initOverlayGallery();
        moveButton();

        // scroll to anchor
        document.querySelectorAll(".anchor-link").forEach(function (link) {
          link.addEventListener("click", function () {
            var dataId = link.getAttribute("data-id");
            scrollToAnchor(dataId);
          });
        });
      }
    );
  });
  // open next item in overlay
  $("body").on(clickMob, ".button-overlay-next", function () {
    $("#c-overlay-container").load(
      $(this).data("link") + " #o-layout__content",
      function () {
        $("#c-overlay-container .c-content-stream").unwrap();
        // initialize overlay gallery
        initOverlayGallery();
        moveButton();
      }
    );
  });

  // overlay close buttons
  $("body").on(clickMob, ".c-overlay-close-button", function () {
    if ($("body").hasClass("mobile")) {
      $("#c-overlay-container").removeClass("open");
      setTimeout(function () {
        $("#c-overlay").delay(400).removeClass("show-overlay-mobile");
      }, 400);
    } else {
      $("#c-overlay-container").removeClass("open");
      setTimeout(function () {
        $("#c-overlay").delay(400).removeClass("show-overlay");
      }, 400);
    }
    $("html, body").removeClass("noscroll");
  });
  // scroll down anchor menu
  if ($("body").hasClass("home")) {
    $("#c-overlay-container").on("scroll", function () {
      if ($(".c-overlay-anchors").length !== 0) {
        var containerPos = $("#c-overlay-container").scrollTop();
        var elementPos = $(".c-overlay-content").scrollTop();
        if (containerPos - elementPos >= 536) {
          if (window.innerWidth > 1024) {
            $(".c-overlay-anchors").addClass("fixed");
            $(".c-overlay-anchors ul").css(
              "margin-top",
              containerPos - 536 + "px"
            );
          }
        } else if (containerPos - elementPos < 536) {
          if (window.innerWidth > 1024) {
            $(".c-overlay-anchors").removeClass("fixed");
            $(".c-overlay-anchors ul").css("margin-top", "0");
          }
        }
      }
    });
  } else if ($("body").hasClass("kantoren") || $("body").hasClass("offices")) {
    $("#c-overlay-container").on("scroll", function () {
      if ($(".c-overlay-anchors").length !== 0) {
        var containerPos = $("#c-overlay-container").scrollTop();
        var elementPos = $(".c-overlay-content").scrollTop();
        if (containerPos - elementPos >= 536) {
          if (window.innerWidth > 1024) {
            $(".c-overlay-anchors").addClass("fixed");
            $(".c-overlay-anchors ul").css(
              "margin-top",
              containerPos - 536 + "px"
            );
          }
        } else if (containerPos - elementPos < 536) {
          if (window.innerWidth > 1024) {
            $(".c-overlay-anchors").removeClass("fixed");
            $(".c-overlay-anchors ul").css("margin-top", "0");
          }
        }
      }
    });
  } else {
    $(window).on("scroll", function () {
      if (
        $(".c-overlay-anchors").length !== 0 &&
        $(".c-content-block").length !== 0
      ) {
        if ($(window).scrollTop() >= $(".c-content-block").offset().top) {
          $(".c-overlay-anchors").addClass("fixed");
        } else {
          $(".c-overlay-anchors").removeClass("fixed");
        }
      }
    });
  }
  // inputs
  var inputs = document.querySelectorAll("textarea, input");
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener("input", function () {
      if (this.value !== "") {
        this.parentNode.classList.add("not-empty");
      } else {
        this.parentNode.classList.remove("not-empty");
      }
      if (this.tagName === "textarea") {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      }
    });
  }
  var selects = document.querySelectorAll("select");
  for (i = 0; i < selects.length; i++) {
    selects[i].addEventListener("change", function () {
      if (this.value !== "") {
        this.parentNode.classList.add("not-empty");
      } else {
        this.parentNode.classList.remove("not-empty");
      }
    });
  }
  // show more button
  $(".c-intro .button-more").on(clickMob, function () {
    $(this).prev("p").toggleClass("expanded");
  });
  // on scroll hide mobile menu button
  $.fn.scrollEnd = function (callback, timeout) {
    $(this).on("scroll", function () {
      if ($(window).scrollTop() > 90) {
        $(".c-menu-open").addClass("fixed");
        $(".c-menu-open").addClass("hidden");
      } else {
        $(".c-menu-open").removeClass("fixed");
      }
      var $this = $(this);
      if ($this.data("scrollTimeout")) {
        clearTimeout($this.data("scrollTimeout"));
      }
      $this.data("scrollTimeout", setTimeout(callback, timeout));
    });
  };
  $(window).scrollEnd(function () {
    $(".c-menu-open").removeClass("hidden");
    if ($(window).scrollTop() + $(window).height() === $(document).height()) {
      $(".c-menu-open").addClass("bottom");
    } else {
      $(".c-menu-open").removeClass("bottom");
    }
  }, 250);
  // close google maps overlay
  $(".button-map").on(clickMob, function () {
    $(".google-map-overlay").addClass("hidden");
    setTimeout(function () {
      $(".google-map-overlay").hide();
    }, 500);
  });
  $(".c-button-down").on(clickMob, function () {
    $("html, body").animate(
      {
        scrollTop: $(".c-intro").offset().top - 88,
      },
      800
    );
  });
  $(".c-button-round").on(clickMob, function () {
    $("html, body").animate(
      {
        scrollTop: $("#o-layout__content-1").offset().top - 88,
      },
      800
    );
  });
  $(".scroll-to").on(clickMob, function () {
    $("html, body").animate(
      {
        scrollTop: $('a[name="contactFormFooter"]').offset().top - 120,
      },
      800
    );
  });
  // check if element is visible in viewport
  var win = $(window);
  var allMods = $(".c-two-columns");
  // Already visible modules
  allMods.each(function (i, el) {
    var element = $(el);
    if (element.visible(true)) {
      if (i % 2 === 0) {
        element.addClass("already-visible even");
      } else {
        element.addClass("already-visible odd");
      }
      element.children(".c-left").addClass("already-visible");
      element.children(".c-right").addClass("already-visible");
    }
  });
  win.scroll(function (event) {
    allMods.each(function (i, el) {
      var element = $(el);
      if (element.visible(true)) {
        if (i % 2 === 0) {
          element.addClass("come-in even");
        } else {
          element.addClass("come-in odd");
        }
        element.children(".c-left").addClass("come-in-left");
        element.children(".c-right").addClass("come-in-right");
      }
    });
  });

  if (window.innerWidth > 1024) {
    if (document.querySelector("body:not(.home)")) {
      setTimeout(function () {
        var sum = 0;
        $(".features .c-feature-list__item").each(function () {
          sum += $(this).height();
        });
        $(".features .c-feature-list-inner").height((sum + 256) / 2);
      }, 200);
    }
  }

  window.addEventListener("togglePanel", function () {
    if (window.innerWidth > 1024) {
      if (document.querySelector("body.home")) {
        setTimeout(function () {
          var sum = 0;
          $(".features .c-feature-list__item").each(function () {
            sum += $(this).height();
          });
          $(".features .c-feature-list-inner").height((sum + 256) / 2);
        }, 200);
      }
    }
  });

  if (document.querySelector(".ht-card-slider")) {
    if (
      document
        .querySelector(".ht-card-slider")
        .classList.contains("card-count-3")
    ) {
      $(".ht-card-slider-cards").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        infinite: false,
        nextArrow:
          '<span class="slider-buttons button-right"><i class="fal fa-arrow-right"></i></span>',
        prevArrow:
          '<span class="slider-buttons button-left"><i class="fal fa-arrow-left"></i></span>',
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 737,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
            },
          },
          {
            breakpoint: 400,
            settings: {
              dots: false,
            },
          },
        ],
      });
    } else {
      $(".ht-card-slider-cards").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        infinite: false,
        nextArrow:
          '<span class="slider-buttons button-right"><i class="fal fa-arrow-right"></i></span>',
        prevArrow:
          '<span class="slider-buttons button-left"><i class="fal fa-arrow-left"></i></span>',
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 737,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
              autoplay: true,
              autoplaySpeed: 5000,
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              autoplay: true,
              autoplaySpeed: 5000,
            },
          },
        ],
      });
    }
  }
});
// change clickable PoI
$(
  ".diensten .c-icon-menu-item, .c-content-stream-5 .c-icon-menu-item, .services .c-icon-menu-item, .c-content-stream-5 .c-icon-menu-item"
).on(clickMob, function () {
  // Get IE or Edge browser version
  var version = detectIE();
  var IE11 = false;
  if (version) {
    IE11 = true;
  }

  function detectIE() {
    var ua = window.navigator.userAgent;

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
    // other browser
    return false;
  }
  if (Modernizr.webgl && !IE11) {
    changePlanes($(this));
    $(".c-context-menu").removeClass("open");
    var category = $(this).data("class");
    $(".flag").each(function () {
      if ($(this).data("category") === category) {
        $(this).removeClass("hide");
        $(this).addClass("show");
      } else {
        $(this).removeClass("show");
        $(this).addClass("hide");
      }
    });
    $(".flag.show").each(function () {
      var selector = $(this).attr("class").split(" ")[0];
      var expandedSelector = $("." + selector + " .flag-inner");
      var diff = Math.abs(
        $("." + selector).height() - expandedSelector.height()
      );
      if (parseInt(expandedSelector.css("padding-top")) > 0) {
        $("." + selector + " .line line").attr("y1", diff - 11);
      } else {
        $("." + selector + " .line line").attr("y1", diff - 6);
      }
    });
  }
});
// if element is visible in viewport add class
$(window).scroll(function (event) {
  $(".c-two-columns").each(function (i, el) {
    var element = $(el);
    if (element.visible(true)) {
      if (i % 2 === 0) {
        element.addClass("come-in even");
      } else {
        element.addClass("come-in odd");
      }
      element.children(".c-left").addClass("come-in-left");
      element.children(".c-right").addClass("come-in-right");
    }
  });
});
$(window).scroll(function (event) {
  if ($(document).scrollTop() > 1) {
    $(
      ".o-layout__header:not(.o-layout-header-visual__desktop):not(.o-layout-header-visual__mobile)"
    ).addClass("shadowed");
  } else {
    $(
      ".o-layout__header:not(.o-layout-header-visual__desktop):not(.o-layout-header-visual__mobile)"
    ).removeClass("shadowed");
  }
});
// function to check if element is visible in viewport
$.fn.visible = function (partial) {
  var $t = $(this);
  var $w = $(window);
  var viewTop = $w.scrollTop();
  var viewBottom = viewTop + $w.height();
  var _top = $t.offset().top;
  var _bottom = _top + $t.height();
  var compareTop = partial === true ? _bottom : _top;
  var compareBottom = partial === true ? _top : _bottom;
  return compareBottom <= viewBottom && compareTop >= viewTop;
};
// init function for the gallery
function init3DGallery() {
  // initialize 3D gallery
  $(".c-gallery-3d-slider")
    .slick({
      dots: false,
      prevArrow: '<div class="c-gallery-3d-nav__previous"></div>',
      nextArrow: '<div class="c-gallery-3d-nav__next"></div>',
      autoplay: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            dots: true,
            dotsClass: "c-gallery-3d-nav",
          },
        },
      ],
    })
    .resize();
  if ($("body").hasClass("mobile")) {
    var w = $(".c-gallery-3d-slider .c-gallery-3d-nav").width();
  }
}
// init function for overlay gallery
function initOverlayGallery() {
  // initialize overlay gallery
  $(".c-overlay-carousel")
    .slick({
      prevArrow: '<div class="c-gallery-nav__previous"></div>',
      nextArrow: '<div class="c-gallery-nav__next"></div>',
      dots: true,
      autoplay: true,
      customPaging: function (slick) {
        return slick.currentSlide + 1 + "/" + slick.slideCount;
      },
    })
    .on("afterChange", function (event, slick, currentSlide) {
      $(this)
        .find("li")
        .text(slick.options.customPaging.call(this, slick, currentSlide))
        .prepend('<i class="material-icons md-light">camera_alt</i>');
    })
    .resize();
  // add icon to slider number
  $(".slick-dots li").prepend(
    '<i class="material-icons md-light">camera_alt</i>'
  );
}

if ($(".ht-office-overview").length > 0) {
  initOverlayGallery();
}

if ($(".anchor-link").length > 0) {
  $(".anchor-link").each(function () {
    $(this).on("click", function () {
      var dataId = $(this).attr("data-id");
      scrollToAnchor(dataId);
    });
  });
}

function toggleMenu() {
  $(".o-layout-nav").toggleClass("closed open");
}

function scrollToAnchor(anchorId) {
  var anchorTag = $(".c-overlay-offices-" + anchorId);
  var offset = 0; // Adjust this value as necessary

  if ($("body").hasClass("home") || $("body").hasClass("page-child")) {
    // Calculate the target scroll position
    var targetScrollTop = anchorTag.position().top + offset;

    // Animate the scroll
    $("html, body").animate({ scrollTop: targetScrollTop }, 500);
  } else {
    // Calculate the target scroll position
    var targetScrollTop = anchorTag.offset().top + offset;

    // Animate the scroll on both 'html' and 'body'
    $("html, body").stop().animate(
      {
        scrollTop: targetScrollTop,
      },
      500
    );
  }
}

function moveButton() {
  if (
    window.innerWidth <= 1024 &&
    document
      .querySelector(".c-overlay")
      .classList.contains("show-overlay-mobile")
  ) {
    var button = document.querySelector("li.list-button a");
    console.log(button);
    document.querySelector(".c-overlay-content").appendChild(button);
  }
}

$(window).scroll(function (event) {
  if ($(document).scrollTop() > 1) {
    $("#header").addClass("scrolled");
  } else {
    $("#header").removeClass("scrolled");
  }
});

function openModal() {
  var getUrl = window.location;
  var baseUrl =
    getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split("/")[1];

  if (document.querySelector(".item-open") !== null) {
    document.querySelectorAll(".item-open").forEach(function (item) {
      item.addEventListener("click", function (e) {
        e.preventDefault();
        var source = item.getAttribute("href");
        $.ajax({
          url: source,
          type: "GET",
          dataType: "html",
          success: function (data) {
            var result = $("<div />").append(data).find(".ty-content").html();
            SimpleLightbox.open({
              content: result,
              elementClass: "slbContentEl",
            });
            $(".slbCloseBtn").html(
              '<img src="' +
                window.location.origin +
                '/wp-content/themes/delftsepoort/img/close.svg" />'
            );
            // feather.replace();
          },
          error: function (xhr, status) {
            console.log("Sorry, there was a problem!");
          },
          complete: function (xhr, status) {
            console.log("Done.");
          },
        });
      });
    });
  }
}

if (document.querySelector(".button-load-more-news") !== null) {
  let pull_page = 1;
  document
    .querySelector(".button-load-more-news")
    .addEventListener("click", function () {
      let jsonFlag = true;

      if (jsonFlag) {
        jsonFlag = false;
        pull_page++;

        if (getLangCode == "nl") {
          $.getJSON(
            "/wp-json/nieuws-en-events/alle-nieuws-en-events/?page=" +
              pull_page,
            function (data) {
              if (data.length) {
                var news_items = [];
                $.each(data, function (index, value) {
                  var number = index + 1;
                  var news_item = "";
                  if (number > 4) {
                    number = 1;
                  }
                  if (number % 3 == 1) {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  } else {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  }
                  news_items.push(news_item);
                });
                $(".ty-news-overview-list").append(news_items);
                if (data.length < 8) {
                  $(".button-load-more-news").hide();
                }
              } else {
                $(".button-load-more-news").hide();
              }
            }
          ).done(function (data) {
            if (data.length) {
              jsonFlag = true;
            }
            openModal();
          });
        } else {
          $.getJSON(
            "/en/wp-json/nieuws-en-events/alle-nieuws-en-events/?page=" +
              pull_page,
            function (data) {
              if (data.length) {
                var news_items = [];
                $.each(data, function (index, value) {
                  var number = index + 1;
                  var news_item = "";
                  if (number > 4) {
                    number = 1;
                  }
                  if (number % 3 == 1) {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  } else {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  }
                  news_items.push(news_item);
                });
                $(".ty-news-overview-list").append(news_items);
                if (data.length < 8) {
                  $(".button-load-more-news").hide();
                }
              } else {
                $(".button-load-more-news").hide();
              }
            }
          ).done(function (data) {
            if (data.length) {
              jsonFlag = true;
            }
            openModal();
          });
        }
      }
    });
}

if (document.querySelector(".button-load-more-news-events") !== null) {
  let pull_page = 1;
  document
    .querySelector(".button-load-more-news-events")
    .addEventListener("click", function () {
      let jsonFlag = true;

      if (jsonFlag) {
        jsonFlag = false;
        pull_page++;

        if (getLangCode == "nl") {
          $.getJSON(
            "/wp-json/nieuws-en-events/alle-events/?page=" + pull_page,
            function (data) {
              if (data.length) {
                var news_items = [];
                $.each(data, function (index, value) {
                  var number = index + 1;
                  var news_item = "";
                  if (number > 4) {
                    number = 1;
                  }
                  if (number % 3 == 1) {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  } else {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  }
                  news_items.push(news_item);
                });
                $(".ty-news-overview-list").append(news_items);
                if (data.length < 8) {
                  $(".button-load-more-news-events").hide();
                }
              } else {
                $(".button-load-more-news-events").hide();
              }
            }
          ).done(function (data) {
            if (data.length) {
              jsonFlag = true;
            }
            openModal();
          });
        } else {
          $.getJSON(
            "/en/wp-json/nieuws-en-events/alle-events/?page=" + pull_page,
            function (data) {
              if (data.length) {
                var news_items = [];
                $.each(data, function (index, value) {
                  var number = index + 1;
                  var news_item = "";
                  if (number > 4) {
                    number = 1;
                  }
                  if (number % 3 == 1) {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  } else {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  }
                  news_items.push(news_item);
                });
                $(".ty-news-overview-list").append(news_items);
                if (data.length < 8) {
                  $(".button-load-more-news-events").hide();
                }
              } else {
                $(".button-load-more-news-events").hide();
              }
            }
          ).done(function (data) {
            if (data.length) {
              jsonFlag = true;
            }
            openModal();
          });
        }
      }
    });
}

if (document.querySelector(".button-load-more-news-news") !== null) {
  let pull_page = 1;
  document
    .querySelector(".button-load-more-news-news")
    .addEventListener("click", function () {
      let jsonFlag = true;

      if (jsonFlag) {
        jsonFlag = false;
        pull_page++;

        if (getLangCode == "nl") {
          $.getJSON(
            "/wp-json/nieuws-en-events/alle-nieuws/?page=" + pull_page,
            function (data) {
              if (data.length) {
                var news_items = [];
                $.each(data, function (index, value) {
                  var number = index + 1;
                  var news_item = "";
                  if (number > 4) {
                    number = 1;
                  }
                  if (number % 3 == 1) {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  } else {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  }
                  news_items.push(news_item);
                });
                $(".ty-news-overview-list").append(news_items);
                if (data.length < 8) {
                  $(".button-load-more-news-news").hide();
                }
              } else {
                $(".button-load-more-news-news").hide();
              }
            }
          ).done(function (data) {
            if (data.length) {
              jsonFlag = true;
            }
            openModal();
          });
        } else {
          $.getJSON(
            "/en/wp-json/nieuws-en-events/alle-nieuws/?page=" + pull_page,
            function (data) {
              if (data.length) {
                var news_items = [];
                $.each(data, function (index, value) {
                  var number = index + 1;
                  var news_item = "";
                  if (number > 4) {
                    number = 1;
                  }
                  if (number % 3 == 1) {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news highlight-' +
                        number +
                        '" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  } else {
                    if (value.info) {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        "</h3>" +
                        "<p>" +
                        value.info +
                        "</p>" +
                        '<div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    } else {
                      news_item =
                        '<a href="' +
                        value.link +
                        '" class="ty-news" style="background-image: url(' +
                        value.featured_img_src +
                        ');"><div class="ty-news-text"><h4>' +
                        value.category +
                        "</h4> <h3>" +
                        value.title +
                        '</h3> <div class="en c-button c-button--arrow-right c-button--primary button-overlay">Read more</div></div></a>';
                    }
                  }
                  news_items.push(news_item);
                });
                $(".ty-news-overview-list").append(news_items);
                if (data.length < 8) {
                  $(".button-load-more-news-news").hide();
                }
              } else {
                $(".button-load-more-news-news").hide();
              }
            }
          ).done(function (data) {
            if (data.length) {
              jsonFlag = true;
            }
            openModal();
          });
        }
      }
    });
}

window.addEventListener("DOMContentLoaded", function () {
  if ($(".ht-image-masonry").length) {
    // When we begin, assume no images are loaded.
    var imagesLoaded = 0;
    // Count the total number of images on the page when the page has loaded.
    var totalImages = $(".ht-image-masonry-img > img").length;

    // After an image is loaded, add to the count, and if that count equals the
    // total number of images, fire the allImagesLoaded() function.
    $(".ht-image-masonry-img > img")
      .on("load", function (event) {
        imagesLoaded++;
        if (imagesLoaded == totalImages) {
          allImagesLoaded();
        }
      })
      .each(function () {
        if (this.complete) $(this).trigger("load");
      });

    function allImagesLoaded() {
      $(".ht-image-masonry").each(function () {
        var msnry = new Masonry(".ht-image-masonry-set", {
          itemSelector: ".ht-image-masonry-item",
          columnWidth: ".ht-image-masonry-item",
          gutter: 16,
          percentPosition: true,
        });
      });

      document.querySelectorAll(".ht-image-masonry").forEach(function () {
        new SimpleLightbox({
          elements: ".ht-image-masonry-set a.ht-image-masonry-img",
        });
      });
    }
  }
});
